<template>
<div class="record-list">
<!--  <DatePicker type="daterange" :start-date="new Date(1991, 4, 14)" placement="bottom-end" placeholder="Select date" clearable style="width: 200px"></DatePicker>-->
  <div class="table">
    <Table :columns="columns" :data="data" :loading="loading"></Table>
  </div>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "recordList",
  data(){
    return {
      columns:[
        {
          title: '时间',
          key: 'created_at',
          render:(h,params)=>{
            return h('span', util.timeFormatter(new Date(+params.row.created_at*1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作类型',
          key: 'type',
          render: (h, para) => {
            return h('span', this.typesObj[para.row.type])
          }
        },
        {
          title: 'IP地址',
          key: 'ip'
        },
      ],
      data:[],
      total:0,
      page:1,
      pageSize:10,
      loading:false,
      userId:'',
      typesObj:{},
    }
  },
  created(){
    if (this.$route.params.id){
        this.userId = this.$route.params.id
    }
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        user_id:this.userId,
        page:this.page,
        pageSize:this.pageSize
      }
      this.api.user.userRecords(params).then((res)=>{
        this.data = res.list;
        this.total = Number(res.count)
        this.typesObj = res.types;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.table{
  margin-top: 20px;
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
