<template>
<div class="personal-info">
  <p class="tit">基本信息</p>
  <div class="personal-cont" v-if="userDetail.auth_roles">
    <div>
      <div>所在组织</div>
      <div v-if="!editSite">
        <span>{{siteName}}(ID:{{siteId}})</span>
        <Button style="margin-left: 10px" type="primary" @click="changeSite">更换</Button>
      </div>

      <div class="user-name" v-else>
        <p>
          <Select v-model="siteId"
                  ref="select"
                  remote
                  :remote-method="remoteSite"
                  class="borerB"
                  size="large"
                  clearable
                  filterable placeholder="请选择"
          >
            <Option v-for="item in siteList" :value="item.id" :key="item.id" >{{ item.name }}</Option>
          </Select>
        </p>
        <Button class="user-name-btn" type="primary" @click="submitSite">提交</Button>
        <Button @click="cancelSite">取消</Button>
      </div>
    </div>
    <div>
      <div>组织内角色</div>
      <div><span v-if="userDetail.auth_roles.role_business">{{userDetail.auth_roles.role_business}},</span><span v-if="userDetail.auth_roles.role_manager">{{userDetail.auth_roles.role_manager}},</span><span v-if="userDetail.auth_roles.role_production">{{userDetail.auth_roles.role_production}},</span><span v-if="userDetail.auth_roles.role_root">{{userDetail.auth_roles.role_root}},</span><span v-if="userDetail.auth_roles.role_student">{{userDetail.auth_roles.role_student}},</span><span v-if="userDetail.auth_roles.role_teacher">{{userDetail.auth_roles.role_teacher}}</span></div>
    </div>
    <div>
      <div>用户ID</div>
      <div>{{ userDetail.id }}</div>
    </div>
    <div>
      <div>用户昵称</div>
      <div>
        <div class="user-name" v-if="!editStatus">
          <p>{{ nickName }}</p>
          <Icon class="user-name-icon" type="ios-create" size="26" color="#4A79FF" @click="edit" />
        </div>
        <div class="user-name" v-else>
          <p><Input v-model="nickName" placeholder="请输入用户昵称" style="width: 300px" /></p>
          <Button class="user-name-btn" type="primary" @click="submit">提交</Button>
          <Button @click="cancel">取消</Button>
        </div>
      </div>
    </div>
    <div>
      <div>姓名</div>
      <div>
        <div class="user-name" v-if="!editRealStatus">
          <p>{{ realname }}</p>
          <Icon class="user-name-icon" type="ios-create" size="26" color="#4A79FF" @click="editReal" />
        </div>
        <div class="user-name" v-else>
          <p><Input v-model="realname" placeholder="请输入用户昵称" style="width: 300px" /></p>
          <Button class="user-name-btn" type="primary" @click="submitReal">提交</Button>
          <Button @click="cancelReal">取消</Button>
        </div>
      </div>
<!--      <div>{{ userDetail.realname }}</div>-->
    </div>
    <div>
      <div>所属院系/部门</div>
      <div>
        <div class="user-name" v-if="!editDepartmentStatus">
          <p>{{ department }}</p>
          <Icon class="user-name-icon" type="ios-create" size="26" color="#4A79FF" @click="editDepartment" />
        </div>
        <div class="user-name" v-else>
          <p><Input v-model="department" placeholder="请输入用户昵称" style="width: 300px" /></p>
          <Button class="user-name-btn" type="primary" @click="submitDepartment">提交</Button>
          <Button @click="cancelDepartment">取消</Button>
        </div>
      </div>
    </div>
<!--    <div>-->
<!--      <div>角色</div>-->
<!--      <div>-->
<!--        <div class="user-name" >-->
<!--          <p>{{ realname }}</p>-->
<!--&lt;!&ndash;          <Icon class="user-name-icon" type="ios-create" size="26" color="#2d8cf0" @click="editReal" />&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
</template>

<script>
export default {
  name: "personalInfo.vue",
  data(){
    return {
      editStatus:false,
      nickName:'',
      realname:'',
      editRealStatus:false,
      editDepartmentStatus:false,
      department:'',
      editSite:false,
      siteList:[],
      siteName:'',
      siteId:'',
    }
  },
  props:{
    userDetail:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    userDetail:{
      // 数据发生变化就会调用这个函数
      handler(newVal) {
        if(newVal.id){
          this.nickName = newVal.nickname;
          this.realname = newVal.realname;
          this.department = newVal.siteUser.department;
          this.siteName =  newVal.site.name;
          this.siteId = newVal.site.id;
          this.siteList = [{
            name:newVal.site.name,
            id:newVal.site.id
          }]
        }
      },
      immediate: true
    }
  },
  created(){
  },
  methods:{
    getSiteList(data){
      let params = {
        op:'searchSite',
        site_name:data
      };
      this.api.index.indexBinding(params).then((res)=>{
        // console.log(params,'params')
        this.siteList = res.siteList;

      })
    },
    remoteSite (query) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.getSiteList(query);
        this.timer = null
      }, 500)

    },
    edit(){
      this.editStatus = true;
    },
    cancel(){
      this.editStatus = false;
      this.nickName = this.userDetail.nickname;
    },
    submit(){
      let params = {
        user_id:this.userDetail.id,
        nickname:this.nickName,
      };
      this.api.user.userUpdate(params).then((res)=>{
        this.$Message.success('修改成功');
        this.editStatus = false;
        this.$emit('editSuccess');
      });
    },
    editReal(){
      this.editRealStatus = true;
    },
    cancelReal(){
      this.editRealStatus = false;
      this.realname = this.userDetail.realname;
    },
    submitReal(){
      let params = {
        user_id:this.userDetail.id,
        realname:this.realname,
      };
      this.api.user.userUpdate(params).then((res)=>{
        this.$Message.success('修改成功');
        this.editRealStatus = false;
        this.$emit('editSuccess');
      });
    },
    editDepartment(){
      this.editDepartmentStatus = true;

    },
    cancelDepartment(){
      this.editDepartmentStatus = false;
      this.department = this.userDetail.siteUser.department;
    },
    submitDepartment(){
      let params = {
        user_id:this.userDetail.id,
        department:this.department,
      };
      this.api.user.userUpdate(params).then((res)=>{
        this.$Message.success('修改成功');
        this.editDepartmentStatus = false;
        this.$emit('editSuccess');
      });
    },
    changeSite(){
      this.editSite = true;
    },
    cancelSite(){
      this.editSite = false;
      this.siteName = this.userDetail.site.name;
      this.siteId = this.userDetail.site.id;
    },
    submitSite(){
      let params = {
        site_id:this.siteId,
      };
      this.api.user.userChangeSite(params).then((res)=>{
        this.$Message.success('修改成功');
        this.editSite = false;
        this.$emit('editSuccess');
      });
    },
  }
}
</script>

<style scoped lang="scss">
.personal-info{
  .tit{
    margin-top: 20px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #DDDDDD;
  }
  .personal-cont{
    margin-top: 20px;
    font-size: 14px;
    >div{
      height: 60px;
      line-height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >div:nth-child(1){
        margin-right: 20px;
        width: 100px;
        text-align: right;
      }
      .user-name{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p{
          margin-right: 10px;
        }
        .user-name-btn{
          margin-right: 10px;
        }
        .user-name-icon{
          display: block;
          cursor: pointer;
        }
      }
      //.user-name:hover{
      //  .user-name-icon{
      //    display: block;
      //    cursor: pointer;
      //  }
      //}

    }
  }
}
</style>
