import { render, staticRenderFns } from "./bindWx.vue?vue&type=template&id=6aad7376&scoped=true&"
import script from "./bindWx.vue?vue&type=script&lang=js&"
export * from "./bindWx.vue?vue&type=script&lang=js&"
import style0 from "./bindWx.vue?vue&type=style&index=0&id=6aad7376&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aad7376",
  null
  
)

export default component.exports