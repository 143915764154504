<template>
<div class="change-password">
  <div class="tit">
    <span @click="back">账号设置</span><span>/修改密码</span>
  </div>
  <div class="change-password-cont">
    <div>
      <div>输入旧密码：</div>
      <Input type="password" v-model="oldPassword" placeholder="请输入旧密码" style="width: 300px" />
      <span style="padding-left: 20px">如未设置过密码，无需输入。</span>
    </div>
    <div>
      <div>输入新密码：</div>
      <Input type="password" v-model="newPassword" placeholder="11-20位数字和字母组合" style="width: 300px" />
    </div>
    <div>
      <div>确认新密码：</div>
      <Input type="password" v-model="againNewPassword" placeholder="确认新密码" style="width: 300px" />
    </div>
    <div class="change-password-cont-btn">
      <Button type="primary" style="width: 100px" @click="confirm" :disabled="!(newPassword && againNewPassword)">确认</Button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "changePassword.vue",
  data(){
    return {
      oldPassword:'',
      newPassword:'',
      againNewPassword:'',
    }
  },
  methods:{
    back(){
      this.$emit('back')
    },
    confirm(){
      var re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/;

      if(!re.test(this.newPassword) || !re.test(this.againNewPassword)){
        this.$Message.warning('请输入正确格式密码');
        return;
      }
      if(this.newPassword != this.againNewPassword){
        this.$Message.warning('两次输入密码不一致，请重新输入');
        return;
      }
      let params = {
        password:this.oldPassword,
        newpassword:this.newPassword,
      };
      this.api.user.updatePassword(params).then((res)=>{
        this.$Message.success('修改成功');
        this.$emit('back')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.change-password{
  font-size: 14px;
  .tit{
    display: flex;
    justify-content: flex-start;
    >span:nth-child(1){
      cursor: pointer;
    }
  }
  .change-password-cont{
    margin: 40px auto;
    width: 600px;
    >div{
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

    }
    .change-password-cont-btn{
      margin-left: 85px;
    }
  }
}
</style>
