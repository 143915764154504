<template>
<div>登录记录</div>
</template>

<script>
export default {
  name: "loginRecord.vue"
}
</script>

<style scoped>

</style>
