<template>
  <div class="user-detail">
    <div class="layout-content">
      <Row>
        <i-col span="2">
          <div class="head-img">
            <Upload :action="constant.URL + '/uploadfile/upload'"
                    :data="{
                      type:3
                    }"
                    accept=".jpg,.png,.jpeg,.gif"
                    :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                    :on-success="fileSuccess"
            >
              <div class="head-img-info">
                <img v-if="userDetail.avatar" :src="userDetail.avatar">
                <p v-else-if="userDetail.nickname">{{userDetail.nickname && userDetail.nickname[0].toUpperCase()}}</p>
                <p class="head-img-change">修改</p>
              </div>

            </Upload>

          </div>
        </i-col>
        <i-col span="22">
          <Row>
            <i-col span="24" class="project-detail-info">
              <div class="info-item" >
                <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">{{ userDetail.nickname }}</strong>
                <!--                <Button type="primary" @click="showEditSite">编辑</Button>-->
              </div>
            </i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
            <i-col span="6" v-if="userDetail.site">所在组织: {{userDetail.site && userDetail.site.name}}(ID:{{userDetail.site && userDetail.site.id}})</i-col>
            <i-col span="6" v-if="userDetail.nickname">昵称 : {{userDetail.nickname}}</i-col>
            <i-col span="6" v-if="userDetail.realname">姓名 : {{userDetail.realname}}</i-col>
            <i-col span="6" v-if="userDetail.mobile">手机号 : {{ userDetail.mobile }}</i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
            <i-col span="6" v-if="userDetail.created_at">加入时间 : {{ userDetail.created_at }}</i-col>
            <i-col span="6" v-if="userDetail.createdByUser">邀请人 : {{ userDetail.createdByUser && userDetail.createdByUser.nickname}}</i-col>
            <i-col span="6" v-if="userDetail.userStat">最后登录时间 : {{ userDetail.userStat && showDate(userDetail.userStat.login_last_time)}}</i-col>
            <i-col span="6" v-if="userDetail.userStat">最后登录IP : {{ userDetail.userStat && userDetail.userStat.login_last_ip}}</i-col>
          </Row>
        </i-col>
      </Row>
    </div>
    <div class="tab-cont">
      <div class="tab-top">
        <div class="tab-top-l">
          <div :class="currentIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">{{item.name}}</div>
        </div>
      </div>
      <component v-if="componentStatus" :is="tabList[currentIndex].componentName" :userDetail="userDetail" :isInfo="true" @editSuccess="editSuccess"></component>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Cookies from "js-cookie";
import RecordList from './detail/recordList';
import PersonalInfo from './detail/personalInfo';
import SetAccount from './detail/setAccount';
// import TeacherAuth from './teacherAuth'
export default {
  name: "userDetail",
  data(){
    return {
      Cookies:Cookies,
      userDetail:{},
      userId:'',
      tabList:[
        {
          name:'个人资料',
          id:1,
          componentName:'PersonalInfo',
          tab:'info'
        },
        {
          name:'账号设置',
          id:2,
          componentName:'SetAccount',
          tab:'account'
        },
        {
          name:'操作记录',
          id:3,
          componentName:'RecordList',
          tab:'record'
        },
        // {
        //   name:'师资认证',
        //   id:4,
        //   componentName:'TeacherAuth',
        //   tab:'teacher'
        // },
      ],
      currentIndex:0,
      tab:'info',
      componentStatus:true,
    }
  },
  components:{
    RecordList,
    PersonalInfo,
    SetAccount,
    // TeacherAuth
  },
  created(){
    if (this.$route.params.tab){
      this.tab = this.$route.params.tab;
      this.currentIndex = this.tabList.findIndex((item)=>{
        return item.tab == this.tab;
      });
    }

    this.getDetail();
  },
  methods:{
    getDetail(){
      let params = {
        with_weixin_subscribe:1
      };
      if (this.userId){
        params.user_id = this.userId
      }

      this.api.user.userDetail(params).then((res)=>{
        this.userDetail = res.user;
        this.userDetail.created_at = util.timeFormatter(new Date(+res.user.created_at*1000), 'yyyy-MM-dd hh:mm');

        this.$store.commit('setUserSites',res.userSites);

        this.$store.state.user.userInfo = {
          ...this.$store.state.user.userInfo,
          ...this.userDetail
        };
        this.$store.commit('login', this.$store.state.user.userInfo);
      })
    },
    changeTab(data){
      if(data == this.currentIndex){
        this.componentStatus = false;
        this.$nextTick(()=>{
          this.componentStatus = true;
        })
        return;
      }
      this.currentIndex = data;
      this.$router.push({
        name:'myinfo',
        params:{
          tab:this.tabList[this.currentIndex].tab
        }
      })
    },
    showDate(time)
    {
      return util.timeFormatter(new Date(+time*1000), 'yyyy-MM-dd hh:mm')
    },
    editSuccess(){
      this.getDetail();
    },
    fileSuccess(response,file, fileList){  //上传文件成功
      console.log(response,'dddd')
      this.userDetail.avatar = response.data.info.upload_path;

      let params = {
        avatar:this.userDetail.avatar,
      };
      this.api.user.updateAvatar(params).then((res)=>{
        this.$Message.success('修改成功');
      })
    },
  }
}
</script>

<style scoped lang="scss">
.user-detail{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);

    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      overflow: hidden;

      .head-img-info{
        position: relative;
        width: 80px;
        height: 80px;
        >img{
          width: 100%;
          height: 100%;
          border-radius: 80px;
        }
        .head-img-change{
          position: absolute;
          left: 0;
          bottom:0;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color:#FFFFFF;
          background-color: rgba(45,140,240,0.9);
          width: 100%;
          font-size: 14px;
        }
      }
    }

    .project-detail-info .info-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .tab-cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    //min-height: 180px;
    .tab-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      .tab-top-l{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border:1px solid #dcdee2;
        height: 100%;
        border-radius: 4px;
        >div{
          width: 150px;
          border-right:1px solid #dcdee2;
          cursor: pointer;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        >div:last-child{
          border-right:none;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }

    }

  }
}
</style>
