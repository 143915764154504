<template>
  <div class="cancellation">
    <div class="tit">
      <span @click="back">账号设置</span><span>/账号注销</span>
    </div>
    <div class="cancellation-cont">
      <div class="cancellation-rule" v-if="status">
        <p class="sub-tit">账号注销则视为您主动放弃以下资产和权益，且同意以下规则：</p>
        <div>
          <p>1.账号注销后，您将无法登录、使用智能题库SaaS账号，且不支持找回任何个人资料。</p>
          <p>2.该账号将解除与其他产品的绑定或授权关系，且不支持找回。</p>
          <p>3.题库购买激活等付费权益，包含已经产生但未消耗完毕的权益或未来预期权益。</p>
          <p>4.将不再支持使用已注销账号的用户ID注册新的账号。</p>
          <p>5.注销智能题库SaaS账号并不代表您注销前的账号行为和相关责任得到豁免或减轻。</p>
        </div>
        <div class="cancellation-rule-btn"><Button type="error" @click="confirm">已知悉，确认注销账号</Button></div>
      </div>
      <div class="cancellation-info" v-else>
        <p class="sub-tit">验证码将发送到手机{{ userDetail.mobile }}</p>
        <Input v-model="mobileCode" class="cancellation-info-input">
          <Button slot="append" @click="getMobileCode">获取短信验证码</Button>
        </Input>
        <Radio v-model="single">我已阅读并同意<span class="cancellation-info-span">【用户协议条款】</span></Radio>
        <div><Button type="error" class="cancellation-info-btn">永久注销</Button></div>
      </div>
    </div>

    <Modal
        v-model="cancellationModal"
        title="注销"
        @on-ok="ok"
        @on-cancel="cancel">
      <p>是否确认注销</p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "cancellation.vue",
  data(){
    return {
      cancellationModal:false,
      mobileCode:'',
      status:true,
      single:false,
    }
  },
  props:{
    userDetail:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  methods:{
    back(){
      this.$emit('back')
    },
    confirm(){
      this.cancellationModal = true;
    },
    ok(){
      this.cancellationModal = false;
      this.status = false;
    },
    cancel(){
      this.cancellationModal = false;
    },
    getMobileCode(){

      let data = {
        op:'sendMobile',
        mobile: this.userDetail.mobile,
      };
      this.api.index.signup(data).then((res)=>{
        this.mobileCodeStatus = false;
        let timer = setInterval(()=>{
          if(this.sec == 0){
            clearInterval(timer);
            this.sec = 60;
            this.mobileCodeStatus = true;
          }else{
            this.sec--;
          }
        },1000)

      })
    },
  }
}
</script>

<style scoped lang="scss">
.cancellation{
  font-size: 14px;
  .tit{
    display: flex;
    justify-content: flex-start;
    >span:nth-child(1){
      cursor: pointer;
    }
  }
  .cancellation-cont{
    margin: 40px auto;
    width: 550px;
    .sub-tit{
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    .cancellation-rule{
      line-height: 30px;

      .cancellation-rule-btn{
        margin: 20px auto;
        text-align: center;
      }
    }
    .cancellation-info{
      .cancellation-info-input{
        margin-bottom: 10px;
        width: 400px;
      }
      .cancellation-info-span{
        color:#2d8cf0;
      }
      .cancellation-info-btn{
        margin: 10px 0;
        width: 400px;
      }
    }
  }
}
</style>
