<template>
<div class="set-account">
  <div v-if="status">
    <p class="tit">账号设置</p>
    <div class="set-account-cont">
      <div class="set-account-cont-item">
        <div class="set-account-cont-item-l">密码</div>
        <div class="set-account-cont-item-r">
          <div></div>
          <div @click="goInfo(0)" class="open">修改密码</div>
        </div>
      </div>
      <div class="set-account-cont-item">
        <div class="set-account-cont-item-l">手机</div>
        <div class="set-account-cont-item-r">
          <div>{{ userDetail.mobile }}</div>
          <div @click="goInfo(1)" class="open">{{userDetail.mobile ? '修改手机' : '绑定手机'}}</div>
        </div>
      </div>
      <div class="set-account-cont-item">
        <div class="set-account-cont-item-l">关注公众号</div>
        <div class="set-account-cont-item-r">
          <div></div>
          <div @click="goInfo(2)" class="open">{{userDetail.weixin_subscribe == '1' ? '已绑定' : '绑定'}}</div>
        </div>
      </div>
      <div class="set-account-cont-item">
        <div class="set-account-cont-item-l">账号注销</div>
        <div class="set-account-cont-item-r">
          <div></div>
          <div @click="goInfo(4)">立即注销</div>
        </div>
      </div>
    </div>
  </div>
  <component v-else :is="componentList[current].componentName" :userDetail="userDetail" @back="back" @editSuccess="editSuccess"></component>
  <Modal
      v-model="followModal"
      title="关注公众号"
      @on-ok="clearTimer"
      @on-cancel="clearTimer"
  >
    <div style="text-align: center">
      <img :src="teacherWxCode" width="200" height="200"  />
    </div>
  </Modal>
</div>
</template>

<script>
import ChangePassword from './changePassword.vue';
import ChangeMobile from './changeMobile.vue';
import BindWx from './bindWx.vue';
import Cancellation from './cancellation.vue';
import LoginRecord from './loginRecord.vue';
export default {
  name: "setAccount.vue",
  data(){
    return {
      componentList:[
        {
          name:'修改密码',
          id:1,
          componentName:'ChangePassword'
        },
        {
          name:'修改手机',
          id:2,
          componentName:'ChangeMobile'
        },
        {
          name:'绑定微信',
          id:3,
          componentName:'BindWx'
        },
        {
          name:'登录记录',
          id:4,
          componentName:'LoginRecord'
        },
        {
          name:'账号注销',
          id:5,
          componentName:'Cancellation'
        },
      ],
      current:0,
      status:true,
      followModal:false,
      teacherWxCode:'',
      timer:null,
      scene:'',
    }
  },
  components:{
    ChangePassword,
    ChangeMobile,
    BindWx,
    Cancellation,
    LoginRecord
  },
  props:{
    userDetail:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  created(){
    this.getImage();
  },
  methods:{
    goInfo(data){
      this.current = data;
      if(data ==0 || data ==1){
        this.status = false;
      }
      if(data == 2){
        if(this.userDetail.weixin_subscribe == '1'){
          this.$Message.warning('已绑定');
          return;
        }
        this.followModal = true;
        clearInterval(this.timer);
        this.timer = setInterval(()=>{
          this.getDetail();
        },1000)
      }

    },
    clearTimer(){
      console.log('2222')
      clearInterval(this.timer);
    },
    getImage(){ //获取二维码图片
      this.api.index.WeixinServerQrShow().then((res)=>{
        this.teacherWxCode = res.showqrcode;
        this.scene = res.scene;
      })
    },
    getDetail(){
      let params = {
        scene:this.scene
      };
      this.api.index.WeixinServerQrBinding(params).then((res)=>{
        if(res.weixin_subscribe == '1'){
          clearInterval(this.timer);
          this.followModal = false;
          this.$emit('editSuccess')
        }
      })
    },
    back(){
      this.status = true;
      this.$emit('back')
    },
    editSuccess(){
      this.$emit('editSuccess')
    },
  },
  destroyed(){
    clearInterval(this.timer);
  }
}
</script>

<style scoped lang="scss">
.set-account{
  .tit{
    margin-top: 20px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #DDDDDD;
  }
  .set-account-cont{
    margin-top: 20px;
    font-size: 14px;
    width: 500px;

    .set-account-cont-item{
      height: 60px;
      line-height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .set-account-cont-item-r{
        display: flex;
        justify-content: flex-start;
        align-items: center;

        >div:nth-child(2){
          width: 80px;
          text-align: right;
          color:#c5c8ce;
          cursor: not-allowed;

        }
      }
      .open{
        color:#2d8cf0 !important;
        cursor: pointer !important;
      }
    }
  }
}
</style>
