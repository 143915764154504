<template>

</template>

<script>
export default {
  name: "bindWx.vue",
  data(){
    return {
      modal:true,
      teacherWxCode:'',
    }
  },
  methods:{
    cancel(){
      this.modal = false;
    },

  }
}
</script>

<style scoped lang="scss">
.bindWx{
  font-size: 14px;
  .tit{
    display: flex;
    justify-content: flex-start;
    >span:nth-child(1){
      cursor: pointer;
    }
  }
}
</style>
