<template>
  <div class="change-mobile">
    <div class="tit">
      <span @click="back">账号设置</span><span>/手机设置</span>
    </div>
    <div class="change-mobile-cont">
      <div class="verification" v-if="step == 1">
        <p class="sub-tit">验证码将发送到手机{{ userDetail.mobile }}</p>
        <p class="sub-tit-tip">如果长时间未收到验证码，请检查是否将运营商拉黑</p>
        <div class="verification-code">
          <p>填写验证码:</p>
          <Input v-model="mobileCode" class="verification-input">
            <Button slot="append" @click="getMobileCode">{{sec != 0 ? sec + '秒后重新获取' : '获取短信验证码'}}</Button>
          </Input>
        </div>
        <div class="verification-btn">
          <Button type="primary" @click="next" :loading="loading">下一步</Button>
        </div>
      </div>
      <div class="verification" v-if="step == 2">
        <div class="verification-code">
          <p>输入新手机号码:</p>
          <Input v-model="newMobile" class="verification-input">
          </Input>
        </div>
        <div class="verification-code">
          <p>填写验证码:</p>
          <Input v-model="newMobileCode" class="verification-input">
            <Button slot="append" @click="getNewMobileCode()">{{sec != 0 ? sec + '秒后重新获取' : '获取短信验证码'}}</Button>
          </Input>
        </div>
        <div class="verification-btn">
          <Button type="primary" @click="newNext" :loading="loading">确认</Button>
        </div>
      </div>
      <div class="verification" style="text-align: center" v-if="step == 3">
        <Icon type="md-checkmark-circle" size="50" color="#19be6b" />
        <p class="success">成功设置/修改手机</p>
        <p class="new-phone">新手机号码为：{{ newMobile }}</p>
        <div class="verification-btn">
          <Button type="primary" @click="editSuccess">完成</Button>
        </div>
      </div>
    </div>
    <Modal
        v-model="confirmBindPhone"
        title="确定要换绑该手机号吗？"
    >
      <div style="margin: 20px 0;font-size: 14px; text-align: center">
        该手机号已绑定ID{{ existUser.id }}的账户，若确定更换将默认与原账号取消绑定
      </div>
      <div slot="footer">
        <Button @click="close">取消</Button>
        <Button type="primary" @click="confirm">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "changeMobile.vue",
  data(){
    return {
      mobileCode:'',
      sec:0,
      newMobile:'',
      step:1,
      timer:null,
      newMobileCode:'',
      mobileHash:'',
      newMobileHash:'',
      loading:false,
      key:'',
      confirmBindPhone:false,
      existUser:{},
    }
  },
  props:{
    userDetail:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  created(){
    if(!this.userDetail.mobile){
      this.step = 2;
    }
  },
  methods:{
    back(){
      this.$emit('back')
    },
    editSuccess(){
      this.$emit('back')
      this.$emit('editSuccess')
    },
    getMobileCode(){
      if(this.sec != 0) return;
      clearInterval(this.timer);
      this.sec = 59;
      this.timer = setInterval(()=>{
        if(this.sec == 0){
          clearInterval(this.timer)
          return;
        };
        this.sec--;
      },1000)
      let params = {
        op:'sendMobile'
      };
      this.api.user.userUpdateMobile(params).then((res)=>{
        this.mobileHash = res.hash;
      })
    },
    getNewMobileCode(deleteExistUser){
      if(this.sec != 0) return;
      if(!this.newMobile){
        this.$Message.warning('请输入新手机号');
        return;
      }

      let params = {
        op:'sendNewMobile',
        mobile:this.newMobile,
        delete_exist_user:deleteExistUser || '0'
      };

      this.api.user.userUpdateMobile(params).then((res)=>{
        if(res.exist_user){
          this.confirmBindPhone = true;
          this.existUser = res.exist_user;
        }else{
          this.newMobileHash = res.hash;
          this.key = res.key;
          clearInterval(this.timer)
          this.sec = 59
          this.timer = setInterval(()=>{
            if(this.sec == 0){
              clearInterval(this.timer)
              return;
            };
            this.sec--;
          },1000)
          this.close();
        }

      })
    },
    next(){
      if(!this.mobileCode){
        this.$Message.warning('请输入验证码');
        return;
      }
      if (!util.captchaCodeCheck(this.mobileCode,this.mobileHash)) {
        this.$Message.warning('请输入正确验证码');
        return;
      }
      let params = {
        op:'verifyPhone',
        mobileCode:this.mobileCode,
      };
      this.loading = true;
      this.api.user.userUpdateMobile(params).then((res)=>{
        this.step = 2;
        this.loading = false;
        this.sec = 0;
      }).catch(()=>{
        this.loading = false;
      })
    },
    newNext(){
      if(!this.newMobile){
        this.$Message.warning('请输入新手机号');
        return;
      }
      if(!this.newMobileCode){
        this.$Message.warning('请输入验证码');
        return;
      }
      if (!util.captchaCodeCheck(this.newMobileCode,this.newMobileHash)) {
        this.$Message.warning('请输入正确验证码');
        return;
      }
      let params = {
        op:'submit',
        mobileCode:this.newMobileCode,
        mobile:this.newMobile,
        key:this.key,
      };
      this.loading = true;
      this.api.user.userUpdateMobile(params).then((res)=>{
        this.step = 3;
        this.loading = false;
      }).catch(()=>{
        this.loading = false;
      })
    },
    close(){
      this.confirmBindPhone = false;
    },
    confirm(){
      this.getNewMobileCode('1');
    }
  }
}
</script>

<style scoped lang="scss">
.change-mobile{
  font-size: 14px;
  .tit{
    display: flex;
    justify-content: flex-start;
    >span:nth-child(1){
      cursor: pointer;
    }
  }
  .sub-tit{
    //margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .sub-tit-tip{
    font-size: 12px;
    color:#c5c8ce;
    margin-bottom: 10px;
  }
  .change-mobile-cont{
    margin: 40px auto;
    width: 400px;

    .verification-code{
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        width: 150px;
        text-align: right;
      }

      .verification-input{
        margin-left: 10px;
        width: 300px;
      }

    }
    .success{
      margin-top: 20px;
      color:#19be6b;
    }
    .new-phone{
      margin-bottom: 20px;
    }
    .verification-btn{
      text-align: center;
      margin: 0 auto;
    }

  }
}
</style>
