<template>
  <div class="my-info">
<!--    <div style="margin: 20px 0 0px 20px">-->
<!--      <MyBreadcrumb></MyBreadcrumb>-->
<!--    </div>-->
    <Head></Head>
    <userDetail></userDetail>
  </div>
</template>
<script>
import userDetail from './components/myInfoDetail'
import Head from '@/views/layout/components/head.vue'
export default {
  name:'my-info',
  data(){
    return {
    }
  },
  components:{
    userDetail,
    Head
  },
  created(){
    this.getUserInfo()
  },
  methods:{
    getUserInfo () {
      // 获取用户信息
      const userInfo = this.$store.state.user.userInfo;

      this.api.index.userDetail().then((res)=>{

        const userDetail = res.user;

        this.$store.commit('setUserSites',res.userSites);

        this.$store.state.app.settings = {
          ...res.settings
        };
        if (userDetail) {
          this.$store.state.user.userInfo = {
            ...this.$store.state.user.userInfo,
            ...userDetail
          };
          this.$store.commit('login', this.$store.state.user.userInfo);

          this.$store.commit('updateModuleMenu', {menu:userDetail.auth_menus, all:this.$store.state.app.moduleList,curModule:'hide'});
        }

      })
    },
  }
}
</script>
<style scoped lang="scss">
</style>
